export const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.355" height="19.355">
    <defs>
      <clipPath id="a">
        <path data-name="Clip 2" d="M0 0h19.355v19.355H0Z" fill="none" />
      </clipPath>
    </defs>
    <path data-name="Clip 2" d="M0 0h19.355v19.355H0Z" fill="none" />
    <g data-name="Fb" clipPath="url(#a)">
      <path
        data-name="Fill 1"
        d="M16.935 0H2.419A2.422 2.422 0 0 0 0 2.419v14.516a2.422 2.422 0 0 0 2.419 2.42h7.258V12.7H7.258V9.677h2.419V7.258a3.629 3.629 0 0 1 3.629-3.629h2.419v3.024h-1.21c-.668 0-1.21-.063-1.21.6v2.424h3.024L15.121 12.7h-1.814v6.653h3.629a2.422 2.422 0 0 0 2.42-2.42V2.419A2.422 2.422 0 0 0 16.935 0"
        fill="#fff"
      />
    </g>
  </svg>
)
