import React, { useRef, useState, useEffect, useCallback } from 'react'

import classnames from 'classnames'
import styled from 'styled-components'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { CONFIG } from '../config'
import { useEffectOnce, useAppContext } from '../hooks'
import { TREE_INDEX } from '../theme'
import { hexToRgb } from '../utils'
import { Dust } from './Dust'
import { Sky } from './Sky'
import { Snow } from './Snow'

const Container = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${TREE_INDEX};

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 70px;
    position: absolute;
    left: 0;
    right: 0;
    top: 53%;
    z-index: -1;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
  }

  canvas {
    pointer-events: none;
    opacity: 0;
    transform: scale(1.1);
    transition: opacity 650ms 1400ms ease-in, transform 600ms 1400ms ease-in;
  }

  &.modelLoaded canvas {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
  }
`

let scene: THREE.Scene = new THREE.Scene()
let camera: THREE.PerspectiveCamera
let controls: OrbitControls

const { AUTO_ROTATE_SPEED, AUTO_ROTATE, DEBUG, PATH, COLORS } = CONFIG.MODEL

const radiansToDegrees = (radians: number): number => {
  const degrees = radians * (180 / Math.PI)
  // console.info(`rotate -> rads: ${radians}, degrees: ${degrees}`);
  return degrees
}

type TreeProps = {
  onModelRotateChange: (degrees: number) => void
}

const color1 = new THREE.Color(COLORS.COLOR_1)
const color2 = new THREE.Color(COLORS.COLOR_2)
const color3 = new THREE.Color(COLORS.COLOR_3)
const color4 = new THREE.Color(COLORS.COLOR_4)

const FOG_NEAR = 1
const FOG_FAR = 1

export const Tree = ({ onModelRotateChange }: TreeProps) => {
  const refContainer = useRef<HTMLDivElement>(null)

  const [RGBColor, setRGBColor] = useState<{ r: number; g: number; b: number }>({ r: 0, g: 0, b: 0 })
  const { modelLoaded, setModelLoaded } = useAppContext()

  const [renderer, setRenderer] = useState<THREE.WebGLRenderer | undefined>(undefined)

  const getColorsFromAngle = (degs: number): { c1: THREE.Color; c2: THREE.Color } => {
    if (degs > 0 && degs <= 90) return { c1: color1, c2: color2 }
    if (degs > 90 && degs <= 180) return { c1: color2, c2: color3 }
    if (degs > 180 && degs <= 270) return { c1: color3, c2: color4 }
    if (degs > 270 && degs < 360) return { c1: color4, c2: color1 }
    return { c1: color1, c2: color2 }
  }

  const adjustFog = () => {
    const degs = radiansToDegrees(camera.rotation.y + Math.PI / 2)
    const { c1, c2 } = getColorsFromAngle(degs)
    const a = degs / 90 - Math.floor(degs / 90)
    const color = new THREE.Color().lerpColors(c1, c2, a)
    const hex = hexToRgb(color.getHexString())
    setRGBColor(hex)
    scene.fog = new THREE.Fog(color, FOG_NEAR, FOG_FAR)
  }

  const animate = useCallback(() => {
    requestAnimationFrame(animate)
    controls.update()
    adjustFog()
    renderer?.render(scene, camera)
  }, [renderer])

  const onControlChange = useCallback(() => {
    // console.info('onControlChange', camera.position, camera.rotation)
    const rads = camera.rotation.y + Math.PI / 2
    onModelRotateChange(radiansToDegrees(rads))
  }, [onModelRotateChange])

  const onWindowResize = useCallback(() => {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer?.setSize(window.innerWidth, window.innerHeight)
    renderer?.render(scene, camera)
  }, [renderer])

  useEffect(() => {
    if (renderer && !modelLoaded) {
      setModelLoaded(true)
      window.addEventListener('resize', onWindowResize, false)
      window.addEventListener('orientationchange', onWindowResize, false)
      controls.addEventListener('change', onControlChange)
      animate()
    }
  }, [renderer, modelLoaded, animate, onWindowResize, onControlChange])

  const Lights = () => {
    const directionalLight = new THREE.DirectionalLight(0xffffff)
    directionalLight.position.set(0, 1, 1).normalize()
    scene.add(directionalLight)
  }

  const Camera = () => {
    const sceneWidth = window.innerWidth
    const sceneHeight = window.innerHeight
    camera = new THREE.PerspectiveCamera(75, sceneWidth / sceneHeight, 0.1, 1000)
    camera.position.set(0, 8, 1)
  }

  const showCenter = () => {
    // Use this to see Center Point Visual and
    const geometry2 = new THREE.CylinderGeometry(0.01, 0.01, 1, 4)
    const material2 = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide })
    const focusPoint = new THREE.Mesh(geometry2, material2)
    focusPoint.rotateX(THREE.MathUtils.degToRad(180))
    focusPoint.scale.set(1, 1, 1)
    focusPoint.position.set(0, 0, 0)
    scene.add(focusPoint)

    // Show scene Axis
    const axesHelper = new THREE.AxesHelper(5)
    scene.add(axesHelper)
  }

  const Action = () => {
    const { current: container } = refContainer
    if (container && !renderer) {
      // Dimensions.
      const sceneWidth = window.innerWidth
      const sceneHeight = window.innerHeight

      // Setup Renderer
      const ren = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      })
      ren.setPixelRatio(window.devicePixelRatio)
      ren.setSize(sceneWidth, sceneHeight)
      ren.outputEncoding = THREE.sRGBEncoding
      container.appendChild(ren.domElement)

      // Orbit controller
      controls = new OrbitControls(camera, ren.domElement)
      controls.enableDamping = true
      controls.dampingFactor = 0.05
      controls.autoRotate = AUTO_ROTATE
      controls.rotateSpeed = 0.3
      controls.minDistance = 4
      controls.maxDistance = 4
      controls.maxPolarAngle = THREE.MathUtils.degToRad(92)
      controls.minPolarAngle = THREE.MathUtils.degToRad(92)
      controls.minZoom = 1.1
      controls.maxZoom = 1.1
      controls.enablePan = false
      controls.autoRotateSpeed = AUTO_ROTATE_SPEED

      // Blackout ground and below
      const geometry = new THREE.CylinderGeometry(3, 3, 5, 50)
      const material = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide, fog: false })
      const cylinder = new THREE.Mesh(geometry, material)
      cylinder.rotateX(THREE.MathUtils.degToRad(180))
      cylinder.scale.set(1, 1, 1)
      cylinder.position.set(0, -2.95, 0)
      scene.add(cylinder)

      if (DEBUG) showCenter()

      // Load Model
      const loader = new GLTFLoader()
      loader.load(
        PATH,
        model => {
          const group = new THREE.Group()

          const obj = model.scene
          obj.name = 'Tree'
          obj.scale.set(1, 1, 1)

          // Offset model position so tree is center of rotation
          obj.position.set(0.55, 0.55, 0)

          obj.traverse(child => {
            if (child.children.length) {
              const points: THREE.Points = child.children[0] as THREE.Points
              const pointsMaterial: THREE.PointsMaterial = points.material as THREE.PointsMaterial
              pointsMaterial.transparent = true
              pointsMaterial.opacity = 0.4
            }
          })

          group.add(obj)
          scene.add(group)

          camera.lookAt(scene.position)

          ren.render(scene, camera)
          setRenderer(ren)
        },
        undefined,
        error => {
          console.error(error)
        },
      )
    }
  }

  useEffectOnce(() => {
    const { current: container } = refContainer
    if (container && !renderer) {
      // Setup Scene
      scene = new THREE.Scene()
      Lights()
      Camera()
      Action()
    }
  })

  return (
    <>
      <Dust rgb={RGBColor} />
      <Container ref={refContainer} className={classnames({ modelLoaded })} />
      <Snow rgb={RGBColor} />
      <Sky rgb={RGBColor} />
    </>
  )
}
