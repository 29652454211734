import React, { useState } from 'react'

import classnames from 'classnames'

import { Tree, AudioControl, Header, Footer, StartScreen, Overlay } from './components'
import { CONFIG } from './config'

const { AUDIO } = CONFIG

function App() {
  const [degrees, setDegrees] = useState<number>(0)
  const [audioTrack, setAudioTrack] = useState<string>(AUDIO.PATH)
  const [openInfo, setOpenInfo] = useState<boolean>(false)
  const [allowAudio, setAllowAudio] = useState<boolean>(false)

  const toggleInfo = () => {
    setOpenInfo(!openInfo)
  }

  const startHandler = () => {
    setAllowAudio(true)
  }

  const stopHandler = () => {
    setAllowAudio(false)
  }

  const loadAudio = (files: FileList | null) => {
    if (files) {
      setAudioTrack(URL.createObjectURL(files[0]))
    }
  }

  return (
    <>
      <Header infoHandler={toggleInfo} openInfo={openInfo} showStop={allowAudio} audioStopHandler={stopHandler}>
        {AUDIO.DEBUG && (
          <input
            style={{
              position: 'relative',
              zIndex: 10,
            }}
            type="file"
            onChange={e => loadAudio(e.target.files)}
            accept="audio/*"
          />
        )}
      </Header>
      <Overlay className={classnames({ show: !allowAudio || openInfo })} />
      <StartScreen startHandler={startHandler} show={!allowAudio && !openInfo} />
      <Tree onModelRotateChange={setDegrees} />
      <AudioControl degrees={degrees} track={audioTrack} allowAudio={allowAudio} />
      <Footer openInfo={openInfo} />
    </>
  )
}

export default App
