import React from 'react'

import classnames from 'classnames'
import styled, { css } from 'styled-components'

import { CloseIcon, InfoIcon, ArtProLogo } from '../Icons'
import { CONFIG } from '../config'
import { HEADER_INDEX, space } from '../theme'

const HeaderElement = styled.header`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: ${HEADER_INDEX};
  text-align: left;
  color: white;
  display: flex;
  flex-direction: row;
  padding-top: 21px;
  padding-bottom: 0;
  padding-left: ${space(4)};
  padding-right: ${space(4)};

  @media (min-width: 400px) {
    padding-left: ${space(8)};
    padding-right: ${space(8)};
  }

  strong {
    position: relative;
    padding-left: 48px;
    transform: translateY(-10px);
    display: block;
    font-size: 12px;
  }

  &:after {
    content: '';
    width: 100%;
    display: block;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 80%);
  }
`

const FilePicker = styled.div`
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  input {
    margin-left: 10px;
  }
`

const UIContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: flex-end;
  button:last-child {
    margin-left: ${space(3)};
  }
`

const iconButtonStyles = css`
  width: 40px;
  height: 40px;
  color: white;
  border: 2px solid white;
  border-radius: 44px;
  padding: 0;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: background-color 280ms ease-in-out, opacity 400ms 400ms ease-in-out, transform 400ms 400ms ease-in-out;
  opacity: 1;

  &.hide {
    transition: background-color 280ms ease-in-out, opacity 400ms ease-in-out, transform 400ms ease-in-out;
    transform: scale(0.75);
    opacity: 0;
  }

  &:before {
    transition: border-color 280ms ease-in-out;
  }
  path,
  text {
    transition: fill 280ms ease-in-out;
  }

  &:active {
    background-color: white;
    path,
    text {
      fill: black;
    }
    &:before {
      border-color: black;
    }
  }
`

const IconButton = styled.button`
  ${iconButtonStyles};
`

const StopButton = styled.button`
  ${iconButtonStyles};

  &:before {
    content: '';
    width: 0;
    height: 14px;
    display: block;
    border-style: double;
    border-width: 0px 0px 0px 10px;
    border-color: white;
  }
`

const Link = styled.a`
  color: white;
  margin-right: ${space(2)};
  .icon {
    height: 100%;
    width: 100%;
    max-width: 220px;
  }
`
const { LOGO } = CONFIG
export const Header = ({
  children,
  infoHandler,
  openInfo,
  showStop,
  audioStopHandler,
}: {
  children: React.ReactNode
  infoHandler: () => void
  audioStopHandler: () => void
  showStop: boolean
  openInfo: boolean
}) => {
  return (
    <HeaderElement>
      <Link href={LOGO.PATH} title={LOGO.TITLE}>
        <ArtProLogo />
      </Link>
      <UIContainer>
        <StopButton className={classnames({ hide: !showStop })} onClick={audioStopHandler} />
        <IconButton onClick={infoHandler}>{openInfo ? <CloseIcon /> : <InfoIcon />}</IconButton>
      </UIContainer>
      <FilePicker>{children}</FilePicker>
    </HeaderElement>
  )
}
