export const RGBAStr = ({ r, g, b, a = 1 }: { r: number; g: number; b: number; a?: number }): string =>
  `rgba(${r},${g},${b},${a})`

export const hexToRgb = (hex: string, fallBack: { r: number; g: number; b: number } = { r: 0, g: 0, b: 0 }) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (result) {
    var r = parseInt(result[1], 16)
    var g = parseInt(result[2], 16)
    var b = parseInt(result[3], 16)
    return { r, g, b }
  }
  return fallBack
}
