const PI2 = Math.PI * 2

export class SnowParticle {
  x: number

  y: number

  vx: number

  vy: number

  radius: number

  randomAlpha: number

  constructor(x: number, y: number, radius: number) {
    this.x = x
    this.y = y
    this.radius = Math.random() < 0.02 ? 4 : radius
    this.vx = -(2 + Math.random() * 4)
    this.vy = Math.random() * 3

    this.randomAlpha = Number((0.2 + Math.random()).toFixed(1))
  }

  animate(
    ctx: CanvasRenderingContext2D,
    stageWidth: number,
    stageHeight: number,
    rgb: { r: number; g: number; b: number },
  ) {
    this.x += this.vx
    this.y += this.vy

    if (this.x < -20) {
      this.x = stageWidth
    } else if (this.x > stageWidth + 20) {
      this.x = -10
    }

    if (this.y < 0) {
      this.y += 10
    } else if (this.y > stageHeight) {
      this.y = -40
    }

    ctx.beginPath()
    const g = ctx.createRadialGradient(this.x, this.y, this.radius * 0.01, this.x, this.y, this.radius)
    g.addColorStop(0, `rgba(${rgb.r},${rgb.g},${rgb.b}, ${this.randomAlpha})`)
    g.addColorStop(1, `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.1)`)
    ctx.fillStyle = g
    ctx.arc(this.x, this.y, this.radius, 0, PI2, false)
    ctx.fill()
  }
}
