import React, { useRef, useEffect } from 'react'

import styled from 'styled-components'

import { useEffectOnce } from '../hooks'
import { FOREGROUND_INDEX } from '../theme'
import { DustParticle } from './Particles'

const Container = styled.canvas`
  /* background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${FOREGROUND_INDEX};
  background: transparent;
  pointer-events: none;

  canvas { */
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  z-index: ${FOREGROUND_INDEX};
  pointer-events: none;
  /* } */
`

let canvasCtx: CanvasRenderingContext2D | null

const black = { r: 0, g: 0, b: 0 }
const white = { r: 255, g: 255, b: 255 }

const baseGradientColors = [black, white]

const dustParticles: DustParticle[] = []

const DUST_PARTICLE_RADIUS = {
  LOW: 1,
  HIGH: 3,
}

type DustProps = {
  rgb?: { r: number; g: number; b: number }
}

export const Dust = ({ rgb = { r: 255, g: 255, b: 255 } }: DustProps) => {
  const canvas = useRef<HTMLCanvasElement>(null)
  const rbgColors = useRef<{ r: number; g: number; b: number }[]>([rgb, rgb, ...baseGradientColors])

  const onWindowResize = () => {
    if (canvasCtx) {
      canvasCtx.canvas.width = window.innerWidth
      canvasCtx.canvas.height = window.innerHeight
    }
  }

  const animateDust = () => {
    if (canvas.current && canvasCtx) {
      let currColor = 0
      for (let i = 0; i < dustParticles.length; i++) {
        const dustItem = dustParticles[i]
        dustItem.animate(canvasCtx, canvas.current.width, canvas.current.height, rbgColors.current[currColor])
        if (currColor++ >= rbgColors.current.length - 1) {
          currColor = 0
        }
      }
    }
  }

  useEffect(() => {
    rbgColors.current = [rgb, rgb, ...baseGradientColors]
  }, [rgb])

  const animate = (time: number) => {
    if (canvas.current && canvasCtx) {
      canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height)
      animateDust()
    }
    requestAnimationFrame(animate)
  }

  const createDustParticles = () => {
    const gradientColors = [rgb, rgb, ...baseGradientColors]
    if (!canvas.current) return
    let currColor = 0
    const particleCount = 50
    for (let i = 0; i < particleCount; i++) {
      const dustItem = new DustParticle(
        Math.random() * canvas.current.width,
        Math.random() * canvas.current.height,
        Math.random() * (DUST_PARTICLE_RADIUS.HIGH - DUST_PARTICLE_RADIUS.LOW) + DUST_PARTICLE_RADIUS.LOW,
      )
      if (currColor++ >= gradientColors.length - 1) {
        currColor = 0
      }
      dustParticles[i] = dustItem
    }
  }

  useEffectOnce(() => {
    if (canvas.current) {
      canvasCtx = canvas.current.getContext('2d')
      onWindowResize()
      window.addEventListener('resize', onWindowResize, false)
      window.addEventListener('orientationchange', onWindowResize, false)
      createDustParticles()
      requestAnimationFrame(animate)
    }
  })

  return <Container ref={canvas} />
}
