const PI2 = Math.PI * 2

export class DustParticle {
  x: number

  y: number

  vx: number

  vy: number

  radius: number

  constructor(x: number, y: number, radius: number) {
    this.x = x
    this.y = y
    this.radius = radius
    this.vx = 1 + Math.random() * 4
    this.vy = 2 + Math.random() * 6
  }

  animate(
    ctx: CanvasRenderingContext2D,
    stageWidth: number,
    stageHeight: number,
    rgb: { r: number; g: number; b: number },
  ) {
    this.x += this.vx
    this.y += this.vy

    if (this.x < -20) {
      this.x = stageWidth
      if (this.vy < 2) {
        this.vy += 1
      }
    } else if (this.x > stageWidth + 20) {
      this.x = -10
      if (this.vy < 2) {
        this.vy += 1
      }
    }

    if (this.y < 0) {
      this.y += 10
    } else if (this.y > stageHeight) {
      this.y = -40
    }

    ctx.beginPath()
    const g = ctx.createRadialGradient(this.x, this.y, this.radius * 0.01, this.x, this.y, this.radius)
    g.addColorStop(0, `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.5)`)
    g.addColorStop(1, `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.0)`)
    ctx.fillStyle = g
    ctx.arc(this.x, this.y, this.radius, 0, PI2, false)
    ctx.fill()
  }
}
