import React, { useState, useRef } from 'react'

import classnames from 'classnames'
import styled, { css } from 'styled-components'

import { FacebookIcon, TwitterIcon, InstagramIcon, LinkedInIcon } from '../Icons'
import { CONFIG } from '../config'
import { useEffectOnce } from '../hooks'
import { Typography, space, FOOTER_INDEX } from '../theme'

const FooterElement = styled.footer<{ footerHeight?: number }>`
  width: 100%;
  position: fixed;
  background: black;
  bottom: 0;
  flex-direction: column;
  left: 0;
  right: 0;
  max-height: 90px;
  min-height: 90px;
  height: auto;
  z-index: ${FOOTER_INDEX};
  display: flex;
  color: white;
  transition: min-height 450ms ease-in-out;

  &:before {
    content: '';
    width: 100%;
    display: block;
    height: 80px;
    position: absolute;
    top: -79px;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(to top, black, transparent 80%);
  }

  &.show {
    min-height: ${({ footerHeight }) => footerHeight}px;
    max-height: ${({ footerHeight }) => footerHeight}px;
  }
`

const Link = styled.a`
  height: 44px;
  color: white;
  border: 2px solid white;
  border-radius: 44px;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  font-family: ${Typography.family.RingsideNarrow};
  font-weight: ${Typography.weight.medium};
  transition: background-color 280ms ease-in-out, color 280ms ease-in-out;

  &:active {
    color: black;
    background-color: white;
  }
`

const panelStyles = css`
  color: white;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20%) scale(0.9);
  transition: opacity 380ms 180ms ease-in-out, transform 400ms 180ms ease-in-out;

  padding-top: ${space(2)};
  padding-bottom: ${space(6)};
  margin-left: ${space(4)};
  margin-right: ${space(4)};

  p {
    font-size: ${Typography.size.base};
  }

  @media (min-width: 400px) {
    margin-left: ${space(8)};
    margin-right: ${space(8)};
  }

  &.show {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: opacity 450ms 200ms ease-in-out, transform 400ms 200ms ease-in-out;
  }
`

const Contact = styled.section`
  ${panelStyles};
  font-family: ${Typography.family.GTSectra};

  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
    font-size: ${Typography.size.small};
  }

  @media (min-width: 400px) {
    p {
      font-size: ${Typography.size.base};
    }
  }

  *:first-child {
    margin-right: auto;
  }
`

const Info = styled.section`
  ${panelStyles};

  position: absolute;
  top: 0;

  ul {
    border-top: 1px solid white;
    margin: 0;
    margin-top: 80px;
    width: 100%;
    display: block;
    padding: 0;
    padding-top: 14px;
    display: flex;
    flex-direction: row;
  }

  li {
    list-style: none;
    margin-right: ${space(5)};
  }

  a {
    display: block;
  }
`
const { CONTACT, SOCIAL } = CONFIG
const { FACEBOOK, INSTAGRAM, TWITTER, LINKED_IN } = SOCIAL

export const Footer = ({ openInfo }: { openInfo: boolean }) => {
  const infoRef = useRef<HTMLElement>(null)
  const [infoHeight, setInfoHeight] = useState<number | undefined>()

  const onWindowResize = () => {
    if (infoRef.current) setInfoHeight(infoRef.current.clientHeight)
  }

  useEffectOnce(() => {
    onWindowResize()
    window.addEventListener('resize', onWindowResize, false)
    window.addEventListener('orientationchange', onWindowResize, false)
  })

  return (
    <>
      <FooterElement footerHeight={infoHeight} className={classnames({ show: openInfo })}>
        <Contact className={classnames({ show: !openInfo })}>
          <p>
            Experiences that
            <br />
            inspire curiosity
          </p>
          <div>
            <Link href={CONTACT.PATH} title="Contact Art Processors" target="_blank">
              Grow with us
            </Link>
          </div>
        </Contact>
        <Info ref={infoRef} className={classnames({ show: openInfo })}>
          <h2>The Listening Tree</h2>
          <p>We designed the Listening Tree for you to visit for a moment of calm.</p>
          <p>
            Sound affects us in all sorts of ways: emotionally, psychologically, even physiologically. A reminder for
            the next time you feel overwhelmed: open your ears for a moment. You might find that by really tuning into
            your surroundings, you can turn chaos into calm. Noise into music.
          </p>
          <p>You can find refuge just in the act of listening.</p>
          <div>
            <ul>
              <li>
                <a href={FACEBOOK.PATH} title={FACEBOOK.TITLE} target="_blank">
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a href={TWITTER.PATH} title={TWITTER.TITLE} target="_blank">
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a href={INSTAGRAM.PATH} title={INSTAGRAM.TITLE} target="_blank">
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a href={LINKED_IN.PATH} title={LINKED_IN.TITLE} target="_blank">
                  <LinkedInIcon />
                </a>
              </li>
            </ul>
          </div>
        </Info>
      </FooterElement>
    </>
  )
}
