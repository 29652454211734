import React from 'react'

import classnames from 'classnames'
import styled, { keyframes } from 'styled-components'

import { HeadphonesIcon } from '../Icons'
import { useAppContext } from '../hooks'
import { Typography } from '../theme'

const Container = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 30;
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  transition: opacity 280ms ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`

const growAndFade = keyframes`
  0% {
    opacity: 0.9;
    transform: scale(0.4);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`

const pulseAndFade = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  15% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(0.9);
  }
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Content = styled.p`
  opacity: 0;
  position: relative;
  transform: translateY(20%);
  transition: opacity 380ms 500ms ease-in-out, transform 400ms 500ms ease-in-out;
  &.loaded {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const Loader = styled.span`
  position: absolute;
  top: 0;
  height: 130px;
  width: 130px;
  transform: scale(1);
  opacity: 0.8;
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;

  &.loaded {
    transform: scale(0.8);
    opacity: 0;
  }

  &:before {
    content: '';
    display: block;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: var(--border-width) solid white;
    border-left-color: rgba(255, 255, 255, 0.7);
    align-self: center;
    margin: auto;
    animation: ${spin} 2s infinite linear;
  }
`

const Ripple = styled.span`
  position: absolute;
  top: 0;
  height: 130px;
  width: 130px;

  .loaded & {
    &:before,
    &:after {
      content: '';
      display: block;
      animation: ${growAndFade} 4s infinite ease-out;
      border-color: white;
      border-left-color: white;
      border-style: solid;
      border-width: var(--border-width);
      border-radius: 50%;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      top: 0;
    }

    &:before {
      animation-delay: 1000ms;
    }
    &:after {
      animation-delay: 2500ms;
    }
  }
`

const ButtonText = styled.span`
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: opacity 200ms ease-in-out, color 200ms ease-in-out;
  svg {
    width: 30px;
  }
  path {
    fill: white;
  }

  .item1,
  .item2 {
    font-size: ${Typography.size.base};
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: ${pulseAndFade} 8100ms infinite linear;
  }
  .item1 {
    animation-delay: 100ms;
  }
  .item2 {
    animation-delay: 4100ms;
  }

  &:before {
    content: '';
    display: block;
    z-index: -1;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 70px;
    height: 70px;
    background: white;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    transform: scale(0.9);
    transition: transform 200ms ease-in-out, opacity 180ms ease-in-out;
  }
`

const StartButton = styled.button`
  --border-width: 5px;
  height: 130px;
  position: relative;
  width: 130px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  background: none;
  margin: 0;
  text-align: center;
  margin: 0 auto;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  font-weight: ${Typography.weight.semiBold};
  text-transform: uppercase;
  pointer-events: none;

  .show &.loaded {
    pointer-events: auto;
    ${ButtonText} {
      display: flex;
    }

    &:active {
      ${ButtonText} {
        color: black;
        path {
          fill: black;
        }
        &:before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
`

type StartScreenProps = {
  startHandler: () => void
  show: boolean
}

export const StartScreen = ({ startHandler, show }: StartScreenProps) => {
  const { loaded } = useAppContext()

  return (
    <>
      <Container className={classnames({ show })}>
        <StartButton onClick={startHandler} className={classnames({ loaded })}>
          <Loader className={classnames({ loaded })} />
          <Ripple />
          <ButtonText>
            <span className="item1">
              <HeadphonesIcon />
            </span>
            <span className="item2">Go</span>
          </ButtonText>
        </StartButton>
        <Content className={classnames({ loaded })}>
          Please use Headphones
          <br /> for the full sound experience
        </Content>
      </Container>
    </>
  )
}
