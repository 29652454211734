import { createGlobalStyle } from 'styled-components'

import { Typography, colors } from '../theme'

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    user-select:none;
  }

  html, body {
    margin: 0;
    padding: 0;
    user-select: none;
  }
  #root {
    height: inherit;
  }

// title - GT Sectra regular 24
// body - Ring side narrow 18

// head phones
// body - Ring side narrow 18
// Button Ring side narrow semi-bold 18

// footer text GT Sectra regular 18
// Button Ring side narrow medium 18

  html {
    height: 100%;
     /* height: -webkit-fill-available; */
    background: ${colors.bg};
    body {
      overflow: hidden;
      background: black;
      height: 100%;
      color: ${colors.text};
      font-size: ${Typography.size.base};
      font-family: ${Typography.family.RingsideNarrow};
      font-weight: ${Typography.weight.normal};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      
    }
    
    img {
      width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 0.6rem;
    }

    h1,
    h2 {
      font-size: ${Typography.size.large};
      font-family: ${Typography.family.GTSectra};
    }

    a {
      text-decoration: none;
      color: white;
    }
  }
`
