const black = '#000000'
const white = '#ffffff'

export const colors = {
  bg: black,
  text: white,
  border: white,
}

export const HEADER_INDEX = 5
export const FOOTER_INDEX = 5
export const FOREGROUND_INDEX = 4
export const TREE_INDEX = 3
export const AUDIO_INDEX = 0
export const SNOW_INDEX = 2
export const SKY_INDEX = 1
export const OVERLAY_INDEX = 4

export const Typography = {
  family: {
    GTSectra: "'GT-Sectra', sans-serif",
    RingsideNarrow: "'RingsideNarrow', sans-serif",
  },
  weight: {
    bold: 700,
    normal: 400,
    medium: 500,
    semiBold: 600,
  },
  size: {
    large: '24px',
    base: '18px',
    small: '16px',
  },
}

const SPACING_MULTIPLIER = 4
export const space = (num: number = 1, suffix: boolean = true): string | number => {
  if (num % 1 !== 0) {
    throw new Error('An integer must be passed.')
  }
  return suffix ? `${num * SPACING_MULTIPLIER}px` : num * SPACING_MULTIPLIER
}
