export const ArtProLogo = () => (
  <svg className="icon" viewBox="0 0 600 120" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M73.89,63.76l-17-30.62h34ZM25.62,85.92,54,34.89l28.34,51ZM58.26,2.83A56.7,56.7,0,1,0,115,59.53,56.7,56.7,0,0,0,58.26,2.83"
    ></path>
    <path
      fill="currentColor"
      d="M155.88,63.4a1.3,1.3,0,0,0,0,.26c0,.28.18.42.53.42h7.5q.54,0,.54-.42a.69.69,0,0,0-.06-.26l-3.93-16.87q-.12-.36-.24-.36h-.12c-.11,0-.2.12-.26.36ZM145.6,79.5l11.25-40.24a1,1,0,0,1,1.16-.91h4.22a1,1,0,0,1,1.15.91L174.64,79.5a1,1,0,0,1,0,.23,1,1,0,0,1-.21.57,1.2,1.2,0,0,1-1,.41h-3.31a1.15,1.15,0,0,1-1.15-.92l-2.66-9.24a1,1,0,0,0-1.16-.86h-10a1.13,1.13,0,0,0-1.15.86l-2.66,9.24a1.15,1.15,0,0,1-1.15.92h-3.34a1.22,1.22,0,0,1-1-.41,1.06,1.06,0,0,1-.2-.57,1,1,0,0,1,0-.23"
    ></path>
    <path
      fill="currentColor"
      d="M187.59,57.43a.52.52,0,0,0,.59.59h5.76a6.51,6.51,0,0,0,2.69-.55,7,7,0,0,0,2.2-1.5,7.38,7.38,0,0,0,1.51-2.2,6.77,6.77,0,0,0,.54-2.72,6.66,6.66,0,0,0-.54-2.69,7.3,7.3,0,0,0-3.71-3.71,6.66,6.66,0,0,0-2.69-.54h-5.76a.52.52,0,0,0-.59.59ZM181.83,79.5V39.56A1.25,1.25,0,0,1,183,38.35h10.9a12.26,12.26,0,0,1,4.92,1,12.78,12.78,0,0,1,6.78,6.78,12.26,12.26,0,0,1,1,4.92,12,12,0,0,1-1.75,6.34,13,13,0,0,1-4.54,4.5,3.14,3.14,0,0,0-1.15,1,1.4,1.4,0,0,0,.12,1.35l7.15,14.71a1.42,1.42,0,0,1,0,1.24,1.18,1.18,0,0,1-1.17.56h-3.39a1.54,1.54,0,0,1-1.1-.34,4.11,4.11,0,0,1-.65-.87l-7.14-15a1.2,1.2,0,0,0-.4-.5,1.25,1.25,0,0,0-.76-.21h-3.69a.52.52,0,0,0-.59.59V79.5a1.27,1.27,0,0,1-1.21,1.21H183a1.12,1.12,0,0,1-.84-.37,1.16,1.16,0,0,1-.37-.84"
    ></path>
    <path
      fill="currentColor"
      d="M212.5,42.9V39.56a1.27,1.27,0,0,1,1.21-1.21h23a1.27,1.27,0,0,1,1.21,1.21V42.9a1.22,1.22,0,0,1-.35.84,1.17,1.17,0,0,1-.86.37h-7.95a.55.55,0,0,0-.62.59V79.5a1.22,1.22,0,0,1-.35.84,1.12,1.12,0,0,1-.83.37h-3.36a1.16,1.16,0,0,1-.85-.37,1.2,1.2,0,0,1-.37-.84V44.7c0-.4-.2-.59-.62-.59h-8a1.27,1.27,0,0,1-1.21-1.21"
    ></path>
    <path
      fill="currentColor"
      d="M267.66,57.43a.52.52,0,0,0,.59.59H274a6.54,6.54,0,0,0,2.69-.55,7.16,7.16,0,0,0,3.7-3.7,6.61,6.61,0,0,0,.55-2.72,6.51,6.51,0,0,0-.55-2.69,7,7,0,0,0-1.5-2.2,7.38,7.38,0,0,0-2.2-1.51,6.7,6.7,0,0,0-2.69-.54h-5.76a.52.52,0,0,0-.59.59ZM261.89,79.5V39.56a1.25,1.25,0,0,1,1.22-1.21H274a12.26,12.26,0,0,1,4.92,1,13,13,0,0,1,4,2.73,12.77,12.77,0,0,1,2.74,4.05,12.7,12.7,0,0,1,0,9.88,13,13,0,0,1-6.78,6.77,12.26,12.26,0,0,1-4.92,1h-5.76a.52.52,0,0,0-.59.59V79.5a1.2,1.2,0,0,1-.37.84,1.16,1.16,0,0,1-.85.37h-3.33a1.16,1.16,0,0,1-.85-.37,1.2,1.2,0,0,1-.37-.84"
    ></path>
    <path
      fill="currentColor"
      d="M300,57.43a.52.52,0,0,0,.59.59h5.76a6.54,6.54,0,0,0,2.69-.55,7.16,7.16,0,0,0,3.7-3.7,6.61,6.61,0,0,0,.55-2.72,6.51,6.51,0,0,0-.55-2.69,7,7,0,0,0-1.5-2.2,7.38,7.38,0,0,0-2.2-1.51,6.7,6.7,0,0,0-2.69-.54h-5.76a.52.52,0,0,0-.59.59ZM294.26,79.5V39.56a1.23,1.23,0,0,1,1.21-1.21h10.9a12.26,12.26,0,0,1,4.92,1,13,13,0,0,1,4,2.73,12.77,12.77,0,0,1,2.74,4.05,12.26,12.26,0,0,1,1,4.92,11.88,11.88,0,0,1-1.76,6.34,12.84,12.84,0,0,1-4.53,4.5,3.05,3.05,0,0,0-1.15,1,1.42,1.42,0,0,0,.11,1.35l7.15,14.71a1.39,1.39,0,0,1,0,1.24,1.19,1.19,0,0,1-1.17.56h-3.4a1.52,1.52,0,0,1-1.09-.34,4.11,4.11,0,0,1-.65-.87l-7.15-15a1.13,1.13,0,0,0-.4-.5,1.19,1.19,0,0,0-.75-.21h-3.69a.52.52,0,0,0-.59.59V79.5a1.2,1.2,0,0,1-.37.84,1.16,1.16,0,0,1-.84.37h-3.34a1.16,1.16,0,0,1-.85-.37,1.18,1.18,0,0,1-.36-.84"
    ></path>
    <path
      fill="currentColor"
      d="M333.38,54.67c-.08,1.58-.12,3.19-.12,4.84s0,3.27.12,4.84.2,3,.36,4.23a9,9,0,0,0,.75,2.56,7,7,0,0,0,1.58,2.19,7.83,7.83,0,0,0,5.53,2.09,7.91,7.91,0,0,0,5.22-1.78,7.8,7.8,0,0,0,2.66-5.06c.16-1.25.29-2.65.39-4.23s.15-3.19.15-4.84,0-3.26-.15-4.84-.23-3-.39-4.22a8.8,8.8,0,0,0-.75-2.57,7,7,0,0,0-1.58-2.18,7.67,7.67,0,0,0-2.36-1.5,8.84,8.84,0,0,0-8.39,1.17,7.23,7.23,0,0,0-2.66,5.08c-.16,1.24-.28,2.65-.36,4.22m-5.74,9.72c-.09-1.6-.14-3.23-.14-4.91s0-3.29.12-4.87.21-3,.39-4.16a16,16,0,0,1,1.31-4.75,13.49,13.49,0,0,1,2.75-4,12.93,12.93,0,0,1,4.12-2.77,13.49,13.49,0,0,1,5.41-1,14.35,14.35,0,0,1,5,.87,12.7,12.7,0,0,1,4.11,2.51,13.35,13.35,0,0,1,2.93,4,16.25,16.25,0,0,1,1.51,5.23c.15,1.2.26,2.6.32,4.19s.09,3.22.09,4.87,0,3.28-.12,4.88-.18,3-.29,4.2a16.52,16.52,0,0,1-1.33,4.79,13.62,13.62,0,0,1-2.74,4A12.55,12.55,0,0,1,347,80.16a13.74,13.74,0,0,1-5.39,1,14.38,14.38,0,0,1-5.06-.87,12.38,12.38,0,0,1-4.1-2.51,13.7,13.7,0,0,1-2.93-4,15.77,15.77,0,0,1-1.5-5.24c-.16-1.21-.28-2.6-.37-4.2"
    ></path>
    <path
      fill="currentColor"
      d="M364.28,64.39c-.09-1.6-.13-3.23-.13-4.91s0-3.29.12-4.87a40.48,40.48,0,0,1,.38-4.16A16.28,16.28,0,0,1,366,45.7a13.49,13.49,0,0,1,2.75-4,13,13,0,0,1,4.12-2.77,13.52,13.52,0,0,1,5.41-1,16.16,16.16,0,0,1,5.21.84,12.74,12.74,0,0,1,4.34,2.48,12.58,12.58,0,0,1,3,4,12,12,0,0,1,1.13,5.28v.86a1.18,1.18,0,0,1-.36.84,1.14,1.14,0,0,1-.86.37h-3.33a1.2,1.2,0,0,1-.84-.37,1.16,1.16,0,0,1-.37-.85v-.85a6.22,6.22,0,0,0-.55-2.57A6.39,6.39,0,0,0,384,45.7a7.79,7.79,0,0,0-2.48-1.5,8.92,8.92,0,0,0-3.28-.57,8.26,8.26,0,0,0-5.2,1.74,7.27,7.27,0,0,0-2.66,5.08c-.16,1.24-.27,2.65-.35,4.22s-.12,3.19-.12,4.84,0,3.27.12,4.84.19,3,.35,4.23a9,9,0,0,0,.75,2.56,7.3,7.3,0,0,0,1.58,2.19,7.88,7.88,0,0,0,5.53,2.09,9.8,9.8,0,0,0,2.94-.45,8.24,8.24,0,0,0,2.52-1.3,6.16,6.16,0,0,0,1.76-2.14,6.24,6.24,0,0,0,.67-2.94v-.82a1.18,1.18,0,0,1,.35-.84,1.15,1.15,0,0,1,.86-.37h3.33a1.22,1.22,0,0,1,.85.35,1.15,1.15,0,0,1,.37.86v.83a11.9,11.9,0,0,1-1,4.81,12.32,12.32,0,0,1-2.82,4,13.72,13.72,0,0,1-4.33,2.74,14.56,14.56,0,0,1-5.51,1,14.32,14.32,0,0,1-5.05-.87,12.32,12.32,0,0,1-4.11-2.51,13.49,13.49,0,0,1-2.92-4,16,16,0,0,1-1.51-5.24c-.16-1.21-.28-2.6-.37-4.2"
    ></path>
    <path
      fill="currentColor"
      d="M401.24,79.5V39.56a1.23,1.23,0,0,1,1.21-1.21H422a1.25,1.25,0,0,1,1.22,1.21V42.9A1.27,1.27,0,0,1,422,44.11H407.59a.51.51,0,0,0-.59.58V55.57a.55.55,0,0,0,.59.62h13.73a1.19,1.19,0,0,1,.85.36,1.16,1.16,0,0,1,.37.86v3.32a1.2,1.2,0,0,1-.37.85,1.16,1.16,0,0,1-.85.37H407.59c-.4,0-.59.21-.59.62V74.36a.52.52,0,0,0,.59.59h14.53a1.27,1.27,0,0,1,1.21,1.21V79.5a1.16,1.16,0,0,1-.37.84,1.12,1.12,0,0,1-.84.37H402.45a1.16,1.16,0,0,1-.85-.37,1.18,1.18,0,0,1-.36-.84"
    ></path>
    <path
      fill="currentColor"
      d="M430.55,68.58v-1a1.2,1.2,0,0,1,.37-.84,1.12,1.12,0,0,1,.83-.37l3.37,0a1.19,1.19,0,0,1,.83.35,1.16,1.16,0,0,1,.36.86v.94a6.61,6.61,0,0,0,.58,2.72,6.1,6.1,0,0,0,1.64,2.18A8.21,8.21,0,0,0,441,74.91a9.14,9.14,0,0,0,3.11.51,9.28,9.28,0,0,0,3.12-.51,7.82,7.82,0,0,0,2.5-1.45,6.31,6.31,0,0,0,1.63-2.18,6.55,6.55,0,0,0,.56-2.69,6.79,6.79,0,0,0-.72-3.38,5.94,5.94,0,0,0-2-2.07,11.2,11.2,0,0,0-3.2-1.3l-4.17-1a16.23,16.23,0,0,1-4.08-1.42A11.54,11.54,0,0,1,434.57,57a10.7,10.7,0,0,1-2.1-3.35,11.94,11.94,0,0,1-.76-4.42,10.59,10.59,0,0,1,1.06-4.83,11,11,0,0,1,2.81-3.57,12.36,12.36,0,0,1,4-2.21,14,14,0,0,1,4.58-.76,13.36,13.36,0,0,1,4.95.9,12.65,12.65,0,0,1,4,2.45,11.47,11.47,0,0,1,2.61,3.61,10.54,10.54,0,0,1,.93,4.41V50a1.18,1.18,0,0,1-.36.84,1.16,1.16,0,0,1-.86.37H452a1.12,1.12,0,0,1-.83-.37,1.19,1.19,0,0,1-.36-.85v-.79a5,5,0,0,0-.61-2.57,5.35,5.35,0,0,0-1.58-1.76,6.44,6.44,0,0,0-2.16-1,9.51,9.51,0,0,0-2.34-.3,7.38,7.38,0,0,0-4.81,1.51,5,5,0,0,0-1.85,4.11,5.15,5.15,0,0,0,.88,3.18,6.69,6.69,0,0,0,2.23,1.88,12,12,0,0,0,2.95,1.07l3.07.69a16.84,16.84,0,0,1,8.1,4.29,11.15,11.15,0,0,1,3,8.21,11.63,11.63,0,0,1-1.15,5.24,12.58,12.58,0,0,1-3,4,13.07,13.07,0,0,1-4.35,2.52,15.13,15.13,0,0,1-5.06.87,14.51,14.51,0,0,1-5.39-1,13.14,13.14,0,0,1-4.31-2.72,12.88,12.88,0,0,1-2.85-4,11.83,11.83,0,0,1-1-4.88"
    ></path>
    <path
      fill="currentColor"
      d="M464.42,68.58v-1a1.16,1.16,0,0,1,.37-.84,1.12,1.12,0,0,1,.83-.37l3.36,0a1.22,1.22,0,0,1,.84.35,1.16,1.16,0,0,1,.36.86v.94a6.45,6.45,0,0,0,.58,2.72,6,6,0,0,0,1.64,2.18,8.21,8.21,0,0,0,2.49,1.42,9.14,9.14,0,0,0,3.11.51,9.28,9.28,0,0,0,3.12-.51,7.82,7.82,0,0,0,2.5-1.45,6.31,6.31,0,0,0,1.63-2.18,6.55,6.55,0,0,0,.56-2.69,6.79,6.79,0,0,0-.72-3.38,5.94,5.94,0,0,0-2-2.07,11.32,11.32,0,0,0-3.2-1.3l-4.17-1a16.23,16.23,0,0,1-4.08-1.42A11.54,11.54,0,0,1,468.44,57a10.35,10.35,0,0,1-2.1-3.35,11.94,11.94,0,0,1-.77-4.42,10.72,10.72,0,0,1,1.06-4.83,11.22,11.22,0,0,1,2.81-3.57,12.41,12.41,0,0,1,4-2.21,14,14,0,0,1,4.58-.76,13.36,13.36,0,0,1,4.95.9,12.65,12.65,0,0,1,4,2.45,11.28,11.28,0,0,1,2.6,3.61,10.39,10.39,0,0,1,.94,4.41V50a1.18,1.18,0,0,1-.36.84,1.17,1.17,0,0,1-.86.37h-3.35a1.16,1.16,0,0,1-.84-.37,1.22,1.22,0,0,1-.35-.85v-.79a5.14,5.14,0,0,0-.61-2.57,5.35,5.35,0,0,0-1.58-1.76,6.53,6.53,0,0,0-2.16-1,9.57,9.57,0,0,0-2.34-.3,7.43,7.43,0,0,0-4.82,1.51,5,5,0,0,0-1.85,4.11,5.15,5.15,0,0,0,.89,3.18,6.69,6.69,0,0,0,2.23,1.88,11.94,11.94,0,0,0,2.94,1.07l3.07.69a16.93,16.93,0,0,1,8.11,4.29,11.15,11.15,0,0,1,3,8.21,11.63,11.63,0,0,1-1.15,5.24,12.46,12.46,0,0,1-3,4,12.93,12.93,0,0,1-4.34,2.52,15.17,15.17,0,0,1-5.06.87,14.51,14.51,0,0,1-5.39-1,13.29,13.29,0,0,1-4.32-2.72,13,13,0,0,1-2.84-4,11.67,11.67,0,0,1-1-4.88"
    ></path>
    <path
      fill="currentColor"
      d="M505.08,54.67c-.07,1.58-.11,3.19-.11,4.84s0,3.27.11,4.84.2,3,.36,4.23a9,9,0,0,0,.75,2.56,7.13,7.13,0,0,0,1.58,2.19,7.88,7.88,0,0,0,5.53,2.09,7.59,7.59,0,0,0,7.89-6.84c.15-1.25.28-2.65.38-4.23s.15-3.19.15-4.84-.05-3.26-.15-4.84-.23-3-.38-4.22a8.82,8.82,0,0,0-.76-2.57,7,7,0,0,0-1.58-2.18,7.67,7.67,0,0,0-2.36-1.5,8.84,8.84,0,0,0-8.39,1.17,7.23,7.23,0,0,0-2.66,5.08c-.16,1.24-.28,2.65-.36,4.22m-5.74,9.72c-.09-1.6-.13-3.23-.13-4.91s0-3.29.11-4.87.21-3,.39-4.16A16,16,0,0,1,501,45.7a13.49,13.49,0,0,1,2.75-4,13,13,0,0,1,4.12-2.77,13.52,13.52,0,0,1,5.41-1,14.27,14.27,0,0,1,5,.87,12.7,12.7,0,0,1,4.11,2.51,13.35,13.35,0,0,1,2.93,4,16.25,16.25,0,0,1,1.51,5.23c.15,1.2.26,2.6.32,4.19s.09,3.22.09,4.87,0,3.28-.12,4.88-.17,3-.29,4.2a16.52,16.52,0,0,1-1.33,4.79,13.62,13.62,0,0,1-2.74,4,12.55,12.55,0,0,1-4.13,2.75,13.74,13.74,0,0,1-5.39,1,14.32,14.32,0,0,1-5-.87,12.32,12.32,0,0,1-4.11-2.51,13.7,13.7,0,0,1-2.93-4,16,16,0,0,1-1.5-5.24c-.16-1.21-.28-2.6-.37-4.2"
    ></path>
    <path
      fill="currentColor"
      d="M542.82,57.43a.52.52,0,0,0,.59.59h5.76a6.51,6.51,0,0,0,2.69-.55,7,7,0,0,0,2.2-1.5,7.38,7.38,0,0,0,1.51-2.2,6.78,6.78,0,0,0,.55-2.72,6.67,6.67,0,0,0-.55-2.69,7.3,7.3,0,0,0-3.71-3.71,6.66,6.66,0,0,0-2.69-.54h-5.76a.52.52,0,0,0-.59.59ZM537.06,79.5V39.56a1.16,1.16,0,0,1,.37-.85,1.18,1.18,0,0,1,.84-.36h10.9a12.19,12.19,0,0,1,4.92,1,12.78,12.78,0,0,1,6.78,6.78,12.27,12.27,0,0,1,1,4.92,12,12,0,0,1-1.76,6.34,12.79,12.79,0,0,1-4.54,4.5,3.14,3.14,0,0,0-1.15,1,1.4,1.4,0,0,0,.12,1.35l7.15,14.71a1.39,1.39,0,0,1,0,1.24,1.16,1.16,0,0,1-1.16.56h-3.4a1.5,1.5,0,0,1-1.09-.34,3.53,3.53,0,0,1-.65-.87l-7.15-15a1.13,1.13,0,0,0-.4-.5,1.23,1.23,0,0,0-.75-.21h-3.7a.52.52,0,0,0-.59.59V79.5a1.16,1.16,0,0,1-.37.84,1.12,1.12,0,0,1-.84.37h-3.34a1.27,1.27,0,0,1-1.21-1.21"
    ></path>
    <path
      fill="currentColor"
      d="M569.39,68.58v-1a1.16,1.16,0,0,1,.37-.84,1.12,1.12,0,0,1,.83-.37l3.36,0a1.2,1.2,0,0,1,.84.35,1.16,1.16,0,0,1,.36.86v.94a6.61,6.61,0,0,0,.58,2.72,6.1,6.1,0,0,0,1.64,2.18,8.21,8.21,0,0,0,2.49,1.42,9.14,9.14,0,0,0,3.11.51,9.28,9.28,0,0,0,3.12-.51,7.82,7.82,0,0,0,2.5-1.45,6.31,6.31,0,0,0,1.63-2.18,6.55,6.55,0,0,0,.56-2.69,6.79,6.79,0,0,0-.72-3.38,5.94,5.94,0,0,0-2-2.07,11.2,11.2,0,0,0-3.2-1.3l-4.17-1a16.23,16.23,0,0,1-4.08-1.42A11.54,11.54,0,0,1,573.41,57a10.35,10.35,0,0,1-2.1-3.35,11.94,11.94,0,0,1-.77-4.42,10.72,10.72,0,0,1,1.06-4.83,11.11,11.11,0,0,1,2.82-3.57,12.26,12.26,0,0,1,4-2.21,14,14,0,0,1,4.58-.76,13.36,13.36,0,0,1,4.95.9,12.65,12.65,0,0,1,4,2.45,11.47,11.47,0,0,1,2.61,3.61,10.54,10.54,0,0,1,.93,4.41V50a1.18,1.18,0,0,1-.36.84,1.16,1.16,0,0,1-.86.37h-3.35a1.12,1.12,0,0,1-.83-.37,1.19,1.19,0,0,1-.36-.85v-.79a5,5,0,0,0-.61-2.57,5.35,5.35,0,0,0-1.58-1.76,6.44,6.44,0,0,0-2.16-1,9.51,9.51,0,0,0-2.34-.3,7.38,7.38,0,0,0-4.81,1.51,5,5,0,0,0-1.85,4.11,5.15,5.15,0,0,0,.88,3.18,6.69,6.69,0,0,0,2.23,1.88,11.94,11.94,0,0,0,2.94,1.07l3.07.69a16.93,16.93,0,0,1,8.11,4.29,11.15,11.15,0,0,1,3,8.21,11.63,11.63,0,0,1-1.15,5.24,12.58,12.58,0,0,1-3,4A13.07,13.07,0,0,1,588,80.31a15.17,15.17,0,0,1-5.06.87,14.51,14.51,0,0,1-5.39-1,13.14,13.14,0,0,1-4.31-2.72,12.88,12.88,0,0,1-2.85-4,11.83,11.83,0,0,1-1-4.88"
    ></path>
  </svg>
)
