import React, { useRef, useState, useEffect, useCallback } from 'react'

import styled from 'styled-components'

import { CONFIG } from '../config'
import { useEffectOnce, useAppContext } from '../hooks'
import { AUDIO_INDEX } from '../theme'

const Container = styled.div`
  margin: 0 auto;
  position: relative;
  z-index: ${AUDIO_INDEX};
`

function floor(value: number, precision: number = 1) {
  var multiplier = Math.pow(10, precision || 0)
  const res = Math.round(value * multiplier) / multiplier
  return res < 0 ? 0 : res
}

// Audio.
let audioSource: MediaElementAudioSourceNode
let audioCtx: AudioContext
let splitter: ChannelSplitterNode
let merger: ChannelMergerNode
let gainL1: GainNode
let gainR1: GainNode
let gainL2: GainNode
let gainR2: GainNode

const { LOOP, GAIN_RANGE } = CONFIG.AUDIO

type AudioControlProps = {
  degrees: number
  track: string
  allowAudio: boolean
}

export const AudioControl = ({ degrees, track, allowAudio }: AudioControlProps) => {
  const audio = useRef<HTMLAudioElement>(new Audio())
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false)

  const { audioLoaded, setAudioLoaded } = useAppContext()

  const getAngle = (angle: number, offset: number) => {
    const semicircleOffset = offset + angle > 180 ? 180 : 0
    return Math.abs(semicircleOffset + (semicircleOffset - (offset + angle)))
  }

  const setupChannels = () => {
    splitter = audioCtx.createChannelSplitter(4)
    audioSource.connect(splitter)
    merger = audioCtx.createChannelMerger(4)

    gainL1 = audioCtx.createGain()
    gainR1 = audioCtx.createGain()
    gainL2 = audioCtx.createGain()
    gainR2 = audioCtx.createGain()

    splitter.connect(gainL1, 0, 0)
    splitter.connect(gainR1, 1, 0)
    splitter.connect(gainL2, 2, 0)
    splitter.connect(gainR2, 3, 0)

    gainL1.connect(merger, 0, 0)
    gainR1.connect(merger, 0, 1)
    gainL2.connect(merger, 0, 2)
    gainR2.connect(merger, 0, 3)
  }

  const adjustTrackChannels = (l1: number, r1: number, l2: number, r2: number) => {
    gainL1.gain.value = l1
    gainR1.gain.value = r1
    gainL2.gain.value = l2
    gainR2.gain.value = r2
    merger.connect(audioCtx.destination)
  }

  const adjustChannels = useCallback((deg: number) => {
    // Gain Range
    const rng = (GAIN_RANGE.TOP - GAIN_RANGE.BOTTOM) * 2 // Times range result by 2 for use with 180/-180 degrees
    const angle = Math.floor(deg)
    const l1 = floor(GAIN_RANGE.BOTTOM + (getAngle(angle, 0) * rng) / 360)
    const r1 = floor(GAIN_RANGE.BOTTOM + (getAngle(angle, 90) * rng) / 360)
    const l2 = floor(GAIN_RANGE.BOTTOM + (getAngle(angle, 180) * rng) / 360)
    const r2 = floor(GAIN_RANGE.BOTTOM + (getAngle(angle, 270) * rng) / 360)
    // console.info(`Angle: ${angle} | Chanel gains: ${l1}, ${r1}, ${l2}, ${r2}`)
    adjustTrackChannels(l1, r1, l2, r2)
  }, [])

  const playSound = () => {
    if (!audio.current) return

    if (audioCtx) {
      audio.current.play()
      setAudioPlaying(true)
      return
    }

    audioCtx = new AudioContext()
    audioSource = audioCtx.createMediaElementSource(audio.current)

    setupChannels()
    console.log('Play')
    audio.current.play()
    setAudioPlaying(true)
  }

  const loadAudio = () => {
    if (audio?.current) {
      if (!audio.current.currentTime) return
      audio.current.pause()
      setAudioPlaying(false)
      setAudioLoaded(false)
      audio.current.src = track
      audio.current.load()
    }
  }

  useEffect(() => {
    if (audioLoaded && !!audio.current && !audio.current.paused) {
      adjustChannels(degrees)
      return
    }
  }, [degrees, adjustChannels, audioLoaded])

  useEffectOnce(() => {
    if (audio.current) {
      audio.current.src = track
      // Initial Audio setup.
      audio.current.load()
      // Event listeners.
      audio.current.addEventListener('loadstart', () => {
        console.log('Audio Load')
      })
      audio.current.addEventListener('canplaythrough', () => {
        console.log('Audio Loaded')
        setAudioLoaded(true)
      })
      audio.current.addEventListener('ended', () => {
        console.log('Audio Ended')
        if (LOOP) audio.current?.play()
      })
    }
  })

  useEffect(() => {
    loadAudio()
  }, [track])

  useEffect(() => {
    if (!allowAudio && audioPlaying) {
      audio.current.pause()
      setAudioPlaying(false)
    }
  }, [allowAudio, audioPlaying])

  useEffect(() => {
    if (allowAudio && !audioPlaying) playSound()
  }, [allowAudio, audioLoaded, audioPlaying])

  return (
    <Container>
      <audio ref={audio} />
    </Container>
  )
}
