import styled from 'styled-components'

import { OVERLAY_INDEX } from '../theme'

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${OVERLAY_INDEX};
  opacity: 0;
  transition: opacity 280ms 200ms ease-in-out;
  pointer-events: none;

  &.show {
    pointer-events: auto;
    transition: opacity 280ms ease-in-out;
    opacity: 1;
  }
`
