export const CONFIG = {
  AUDIO: {
    PATH: '/v16.wav',
    DEBUG: false,
    LOOP: true,
    GAIN_RANGE: {
      BOTTOM: -5,
      TOP: 15,
    },
  },
  MODEL: {
    PATH: '/tree.gltf',
    DEBUG: false,
    AUTO_ROTATE_SPEED: 0.5,
    AUTO_ROTATE: true,
    COLORS: {
      COLOR_1: '#f2ab39', // yellow
      COLOR_2: '#008797', // teal
      COLOR_3: '#b84f37', // red
      COLOR_4: '#78b457', // green
    },
  },
  LOGO: {
    PATH: 'https://www.artprocessors.net/',
    TITLE: 'Art Processors',
  },
  CONTACT: {
    PATH: 'https://www.artprocessors.net/contact',
    TITLE: 'Contact Art Processors',
  },
  SOCIAL: {
    FACEBOOK: {
      PATH: 'https://www.facebook.com/Art.Processors',
      TITLE: 'Facebook - Art Processors',
    },
    TWITTER: {
      PATH: 'https://twitter.com/ArtProcessors',
      TITLE: 'Twitter - Art Processors',
    },
    INSTAGRAM: {
      PATH: 'https://www.instagram.com/artprocessors/',
      TITLE: 'Instagram - Art Processors',
    },
    LINKED_IN: {
      PATH: 'https://www.linkedin.com/company/art-processors/mycompany/verification/',
      TITLE: 'LinkedIn - Art Processors',
    },
  },
}
