export const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.355" height="19.355">
    <defs>
      <clipPath id="a">
        <path data-name="Clip 2" d="M0 0h19.355v19.355H0Z" fill="none" />
      </clipPath>
    </defs>
    <g data-name="Group 3">
      <path data-name="Clip 2" d="M0 0h19.355v19.355H0Z" fill="none" />
      <g data-name="Group 3" clipPath="url(#a)">
        <path
          data-name="Fill 1"
          d="M13.306 19.354H6.048A6.055 6.055 0 0 1 0 13.306V6.048A6.055 6.055 0 0 1 6.048 0h7.258a6.055 6.055 0 0 1 6.048 6.048v7.258a6.055 6.055 0 0 1-6.048 6.048ZM6.048 1.814a4.238 4.238 0 0 0-4.234 4.234v7.258a4.238 4.238 0 0 0 4.234 4.234h7.258a4.238 4.238 0 0 0 4.234-4.234V6.048a4.238 4.238 0 0 0-4.234-4.234Z"
          fill="#fff"
        />
      </g>
    </g>
    <path
      data-name="Fill 4"
      d="M9.678 14.517a4.839 4.839 0 1 1 4.839-4.84 4.845 4.845 0 0 1-4.839 4.84Zm0-7.863a3.024 3.024 0 1 0 3.024 3.023 3.028 3.028 0 0 0-3.024-3.024Z"
      fill="#fff"
    />
    <path data-name="Fill 6" d="M15.523 4.476a.645.645 0 1 1-.644-.645.645.645 0 0 1 .645.645" fill="#fff" />
  </svg>
)
