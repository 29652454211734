import React, { useRef, useEffect } from 'react'

import styled from 'styled-components'

import { useEffectOnce } from '../hooks'
import { SKY_INDEX } from '../theme'
import { SkyParticle } from './Particles'

const Container = styled.div`
  mask-image: url('/sky-mask.png');
  mask-size: 130% 65%;
  mask-position: 50% 10%;
  mask-repeat: no-repeat;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${SKY_INDEX};
  pointer-events: none;

  canvas {
    display: block;
    opacity: 0.3;
    background: black;
    position: absolute;
    top: 30px;
    left: 0;
    bottom: 0;
    right: 0;
    filter: blur(100px), saturate(10);
  }
`

let canvasCtx: CanvasRenderingContext2D | null

const black = { r: 0, g: 0, b: 0 }
const baseSkyGradientColors = [black, black]
const skyParticles: SkyParticle[] = []
const SKY_PARTICLE_COUNT = 50
const SKY_PARTICLE_RADIUS = {
  LOW: 300,
  HIGH: 800,
}

type SkyProps = {
  rgb?: { r: number; g: number; b: number }
}
export const Sky = ({ rgb = { r: 0, g: 0, b: 0 } }: SkyProps) => {
  const canvas = useRef<HTMLCanvasElement>(null)
  const rbgColors = useRef<{ r: number; g: number; b: number }[]>([rgb, rgb, ...baseSkyGradientColors])

  const onWindowResize = () => {
    if (canvasCtx) {
      canvasCtx.canvas.width = window.innerWidth
      canvasCtx.canvas.height = window.innerHeight
    }
  }

  const animateSky = () => {
    if (canvas.current && canvasCtx) {
      let currColor = 0

      for (let i = 0; i < skyParticles.length; i++) {
        const skyItem = skyParticles[i]
        skyItem.animate(canvasCtx, canvas.current.width, canvas.current.height, rbgColors.current[currColor])
        if (currColor++ >= rbgColors.current.length - 1) {
          currColor = 0
        }
      }
    }
  }

  useEffect(() => {
    rbgColors.current = [rgb, rgb, ...baseSkyGradientColors]
  }, [rgb])

  const animate = () => {
    if (canvas.current && canvasCtx) {
      canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height)
      animateSky()
    }
    requestAnimationFrame(animate)
  }

  const createSkyParticles = () => {
    if (!canvas.current) return
    const gradientColors = [rgb, rgb, ...baseSkyGradientColors]

    let currColor = 0
    for (let i = 0; i < SKY_PARTICLE_COUNT; i++) {
      const skyItem = new SkyParticle(
        Math.random() * canvas.current.width,
        Math.random() * canvas.current.height,
        Math.random() * (SKY_PARTICLE_RADIUS.HIGH - SKY_PARTICLE_RADIUS.LOW) + SKY_PARTICLE_RADIUS.LOW,
      )
      if (currColor++ >= gradientColors.length - 1) {
        currColor = 0
      }
      skyParticles[i] = skyItem
    }
  }

  useEffectOnce(() => {
    if (canvas.current) {
      canvasCtx = canvas.current.getContext('2d')
      onWindowResize()
      window.addEventListener('resize', onWindowResize, false)
      window.addEventListener('orientationchange', onWindowResize, false)
      createSkyParticles()
      requestAnimationFrame(animate)
    }
  })

  return (
    <Container>
      <canvas ref={canvas} />
    </Container>
  )
}
