export const CloseIcon = () => (
  <svg
    id="Close_button"
    data-name="Close button"
    xmlns="http://www.w3.org/2000/svg"
    width="38.063"
    height="38.063"
    viewBox="0 0 38.063 38.063"
  >
    {/* <g id="Close_button-2" data-name="Close button">
      <g id="Path_15" data-name="Path 15" fill="none">
        <path d="M19.031,0A19.031,19.031,0,1,1,0,19.031,19.031,19.031,0,0,1,19.031,0Z" stroke="none" />
        <path
          d="M 19.03131103515625 1 C 14.2149715423584 1 9.686910629272461 2.875579833984375 6.281251907348633 6.281251907348633 C 2.875579833984375 9.686910629272461 1 14.2149715423584 1 19.03131103515625 C 1 23.84765243530273 2.875579833984375 28.37571144104004 6.281251907348633 31.7813720703125 C 9.686910629272461 35.18704223632812 14.2149715423584 37.0626220703125 19.03131103515625 37.0626220703125 C 23.84765243530273 37.0626220703125 28.37571144104004 35.18704223632812 31.7813720703125 31.7813720703125 C 35.18704223632812 28.37571144104004 37.0626220703125 23.84765243530273 37.0626220703125 19.03131103515625 C 37.0626220703125 14.2149715423584 35.18704223632812 9.686910629272461 31.7813720703125 6.281251907348633 C 28.37571144104004 2.875579833984375 23.84765243530273 1 19.03131103515625 1 M 19.03131103515625 0 C 29.54201126098633 0 38.0626220703125 8.520610809326172 38.0626220703125 19.03131103515625 C 38.0626220703125 29.54201126098633 29.54201126098633 38.0626220703125 19.03131103515625 38.0626220703125 C 8.520610809326172 38.0626220703125 0 29.54201126098633 0 19.03131103515625 C 0 8.520610809326172 8.520610809326172 0 19.03131103515625 0 Z"
          stroke="none"
          fill="#fff"
        />
      </g>
    </g> */}
    <g id="Group_35" data-name="Group 35" transform="translate(12.12 11.838)">
      <path
        id="Line_7"
        data-name="Line 7"
        d="M12.185,13.053-.707.161.161-.707,13.053,12.185Z"
        transform="translate(0.707 0.707)"
        fill="#fff"
      />
      <path
        id="Line_8"
        data-name="Line 8"
        d="M.161,13.053l-.868-.868L12.185-.707l.868.868Z"
        transform="translate(0.707 0.707)"
        fill="#fff"
      />
    </g>
  </svg>
)
