import { useEffect, createContext, useRef, useContext, useState, useMemo, Context } from 'react'

export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>()
  const calledOnce = useRef(false)
  const renderAfterCalled = useRef(false)

  if (calledOnce.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    if (calledOnce.current) {
      return
    }

    calledOnce.current = true
    destroyFunc.current = effect()

    return () => {
      if (!renderAfterCalled.current) {
        return
      }

      if (destroyFunc.current) {
        destroyFunc.current()
      }
    }
  }, [])
}

type AppContextType = {
  loaded: boolean
  audioLoaded: boolean
  modelLoaded: boolean
  setAudioLoaded: (state: boolean) => void
  setModelLoaded: (state: boolean) => void
}

const AppContext: Context<AppContextType> = createContext<AppContextType>({
  loaded: false,
  audioLoaded: false,
  modelLoaded: false,
  setAudioLoaded: (state: boolean) => {},
  setModelLoaded: (state: boolean) => {},
})

export function useAppContext() {
  const context = useContext<AppContextType>(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider')
  }
  return context
}

export function AppProvider(props: { children: React.ReactNode }) {
  const [audioLoaded, setAudioLoaded] = useState<boolean>(false)
  const [modelLoaded, setModelLoaded] = useState<boolean>(false)

  const value = useMemo(() => {
    const loaded = audioLoaded && modelLoaded
    return {
      loaded,
      audioLoaded,
      modelLoaded,
      setAudioLoaded,
      setModelLoaded,
    }
  }, [modelLoaded, audioLoaded])
  return <AppContext.Provider value={value} {...props} />
}
