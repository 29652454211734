import React, { useRef, useEffect } from 'react'

import styled from 'styled-components'

import { useEffectOnce } from '../hooks'
import { SNOW_INDEX } from '../theme'
import { SnowParticle } from './Particles'

const Container = styled.canvas`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  z-index: ${SNOW_INDEX};
  pointer-events: none;
`

let canvasCtx: CanvasRenderingContext2D | null

const white1 = { r: 255, g: 255, b: 255 }
const white2 = { r: 236, g: 236, b: 236 }
const white3 = { r: 201, g: 201, b: 201 }

const snowGradientColors = [white1, white2, white3]
const snowParticles: SnowParticle[] = []
const SNOW_PARTICLE_COUNT = 400
const SNOW_PARTICLE_RADIUS = {
  LOW: 0.3,
  HIGH: 2,
}

type SnowProps = {
  rgb?: { r: number; g: number; b: number }
}
export const Snow = ({ rgb = { r: 255, g: 255, b: 255 } }: SnowProps) => {
  const canvas = useRef<HTMLCanvasElement>(null)
  const rbgColors = useRef<{ r: number; g: number; b: number }[]>([rgb, ...snowGradientColors])

  const onWindowResize = () => {
    if (canvasCtx) {
      canvasCtx.canvas.width = window.innerWidth
      canvasCtx.canvas.height = window.innerHeight
    }
  }

  const animateSnow = () => {
    if (canvas.current && canvasCtx) {
      let currColor = 0

      for (let i = 0; i < snowParticles.length; i++) {
        const snowItem = snowParticles[i]
        snowItem.animate(canvasCtx, canvas.current.width, canvas.current.height, rbgColors.current[currColor])
        if (currColor++ >= rbgColors.current.length - 1) {
          currColor = 0
        }
      }
    }
  }

  useEffect(() => {
    rbgColors.current = [rgb, ...snowGradientColors]
  }, [rgb])

  const animate = () => {
    if (canvas.current && canvasCtx) {
      canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height)
      animateSnow()
    }
    requestAnimationFrame(animate)
  }

  const createSnowParticles = () => {
    if (!canvas.current) return
    const gradientColors = [rgb, rgb, ...snowGradientColors]

    let currColor = 0
    for (let i = 0; i < SNOW_PARTICLE_COUNT; i++) {
      const snowItem = new SnowParticle(
        Math.random() * canvas.current.width,
        Math.random() * canvas.current.height,
        Math.random() * (SNOW_PARTICLE_RADIUS.HIGH - SNOW_PARTICLE_RADIUS.LOW) + SNOW_PARTICLE_RADIUS.LOW,
      )
      if (currColor++ >= gradientColors.length - 1) {
        currColor = 0
      }
      snowParticles[i] = snowItem
    }
  }

  useEffectOnce(() => {
    if (canvas.current) {
      canvasCtx = canvas.current.getContext('2d')
      onWindowResize()
      window.addEventListener('resize', onWindowResize, false)
      window.addEventListener('orientationchange', onWindowResize, false)
      createSnowParticles()
      requestAnimationFrame(animate)
    }
  })

  return <Container ref={canvas} />
}
