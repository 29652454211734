export const InfoIcon = () => (
  <svg
    id="Info_button"
    data-name="Info button"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    {/* <g id="Path_15" data-name="Path 15" fill="none">
      <path d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" stroke="none" />
      <path
        d="M 20 1 C 17.4345703125 1 14.94642066955566 1.502220153808594 12.60465049743652 2.492698669433594 C 10.34226036071777 3.449611663818359 8.310220718383789 4.819721221923828 6.564968109130859 6.564968109130859 C 4.819721221923828 8.310220718383789 3.449611663818359 10.34226036071777 2.492698669433594 12.60465049743652 C 1.502220153808594 14.94642066955566 1 17.4345703125 1 20 C 1 22.5654296875 1.502220153808594 25.05358123779297 2.492698669433594 27.39535140991211 C 3.449611663818359 29.65773963928223 4.819721221923828 31.68978118896484 6.564968109130859 33.43502807617188 C 8.310220718383789 35.18027877807617 10.34226036071777 36.55038833618164 12.60465049743652 37.50730133056641 C 14.94642066955566 38.49777984619141 17.4345703125 39 20 39 C 22.5654296875 39 25.05358123779297 38.49777984619141 27.39535140991211 37.50730133056641 C 29.65773963928223 36.55038833618164 31.68978118896484 35.18027877807617 33.43502807617188 33.43502807617188 C 35.18027877807617 31.68978118896484 36.55038833618164 29.65773963928223 37.50730133056641 27.39535140991211 C 38.49777984619141 25.05358123779297 39 22.5654296875 39 20 C 39 17.4345703125 38.49777984619141 14.94642066955566 37.50730133056641 12.60465049743652 C 36.55038833618164 10.34226036071777 35.18027877807617 8.310220718383789 33.43502807617188 6.564968109130859 C 31.68978118896484 4.819721221923828 29.65773963928223 3.449611663818359 27.39535140991211 2.492698669433594 C 25.05358123779297 1.502220153808594 22.5654296875 1 20 1 M 20 0 C 31.04570007324219 0 40 8.954299926757812 40 20 C 40 31.04570007324219 31.04570007324219 40 20 40 C 8.954299926757812 40 0 31.04570007324219 0 20 C 0 8.954299926757812 8.954299926757812 0 20 0 Z"
        stroke="none"
        fill="#fff"
      />
    </g> */}
    <text id="i" transform="translate(17 26)" fill="#fff" fontSize="21" fontFamily="GT-Sectra" fontWeight="500">
      <tspan x="0" y="0">
        i
      </tspan>
    </text>
  </svg>
)
