export const LinkedInIcon = () => (
  <svg id="LinkedIn" xmlns="http://www.w3.org/2000/svg" width="19.355" height="19.355" viewBox="0 0 19.355 19.355">
    <defs>
      <clipPath id="clip-path">
        <path id="Clip_3" data-name="Clip 3" d="M0,0H13.306V13.306H0Z" fill="none" />
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Clip_6" data-name="Clip 6" d="M0,0H4.234V4.234H0Z" fill="none" />
      </clipPath>
    </defs>
    <g id="Li">
      <path id="Fill_1" data-name="Fill 1" d="M0,13.306H4.328V0H0Z" transform="translate(0 6.048)" fill="#fff" />
      <g id="Group_8" data-name="Group 8">
        <g id="Group_4" data-name="Group 4" transform="translate(6.048 6.048)">
          <path id="Clip_3-2" data-name="Clip 3" d="M0,0H13.306V13.306H0Z" fill="none" />
          <g id="Group_4-2" data-name="Group 4" clipPath="url(#clip-path)">
            <path
              id="Fill_2"
              data-name="Fill 2"
              d="M10.069.156c-.046-.014-.09-.03-.138-.044S9.815.088,9.756.079A3.88,3.88,0,0,0,8.979,0,6.014,6.014,0,0,0,4.328,2.544V0H0V13.307H4.328V6.048S7.6,1.493,8.979,4.839v8.468h4.327V4.327A4.316,4.316,0,0,0,10.069.156"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_7" data-name="Group 7">
          <path id="Clip_6-2" data-name="Clip 6" d="M0,0H4.234V4.234H0Z" fill="none" />
          <g id="Group_7-2" data-name="Group 7" clipPath="url(#clip-path-2)">
            <path
              id="Fill_5"
              data-name="Fill 5"
              d="M4.234,2.117A2.117,2.117,0,1,1,2.117,0,2.117,2.117,0,0,1,4.234,2.117"
              transform="translate(0)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
